@import 'variables/variables';

// TODO: update color placeholder
.mat-expanded {
  overflow: initial !important;
}

.chart-type-group {
  border-radius: 3px;

  .mat-button-toggle {
    width: 70px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mat-button-toggle-group {
    box-shadow: none;
  }

  .mat-button-toggle-label-content {
    font-size: 12px;
  }
}

.table {
  width: 100%;
  min-width: 100%;

  th,
  td {
    padding: 6px 12px;
    font-size: 14px;
  }

  th {
    font-size: 11px !important;
    font-weight: 600;
    color: $font-color-1;
  }
}

.wrapper {
  -webkit-user-select: auto !important;
  /* Chrome 49+ */
  -moz-user-select: auto !important;
  /* Firefox 43+ */
  -ms-user-select: auto !important;
  /* No support yet */
  user-select: auto !important;
  /* Likely future */
}

app-main > .mat-sidenav-container > .mat-sidenav-content #app-main-content #wrapper .content-wrapper app-content,
app-main > .mat-sidenav-container > .mat-drawer-content #app-main-content #wrapper .content-wrapper app-content {
  z-index: 99;
}

.mat-calendar-body-selected {
  background-color: $am-primary !important;
}

gridster-item .footer {
  margin-top: -52px;
}

ngx-gridster-item.is-full {
  width: 100% !important;
}

ngx-gridster ngx-gridster-item.is-full {
  .gridster-item-resizable-handler.handle-se {
    bottom: 24px !important;
    right: 0px !important;
  }
}

.is-half {
  &:not(.left-pos) {
    padding-right: 12px;
  }

  box-sizing: border-box;

  .card-container {
    &.is-summary {
      height: auto !important;
    }
  }

  .sale-item {
    width: 50%;
    flex: 0 0 50% !important;

    &:nth-child(2n) {
      border-right: none !important;
    }

    .content-sale {
      padding-left: 16px !important;

      .sale-item-title {
        font-size: 13px !important;
      }
    }
  }

  &.left-pos {
    left: 12px !important;

    .gridster-item-resizable-handler.handle-se {
      bottom: 24px !important;
      right: 0px !important;
    }
  }
}

[hidden] {
  display: none !important;
}

mat-paginator {
  display: block;
  margin-top: 16px;

  .mat-paginator-page-size {
    position: relative;
    bottom: -4px;
  }

  .mat-paginator-page-size-select {
    margin: 6px 4px 0 4px;
    width: 56px;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 8px 8px 0;
    background-color: $bg-color-1;
    border: solid 1px $border-color-1;
    color: $font-color-1;
    width: 240px;
    height: 30px;
  }

  mat-select {
    outline: none !important;
    padding: 0 !important;
  }

  .mat-paginator-range-label {
    color: $font-color-1;
  }

  .mat-icon-button:not(.mat-button-disabled) {
    color: $font-color-1;
  }
}

.wrapper .footer {
  color: $am-primary !important;
}

.mat-button-toggle-group:not(.preserve-default),
.mat-button-toggle-standalone,
.monthly-header .chart-type-group,
.monthly-header .metric-wrapper,
.clicker {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.report-result-controls button.is-download,
.wrapper .container .fixed-component .edit-query {
  background-color: $am-primary;
  border: solid 1px $am-primary !important;

  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-size: 14px;
  font-weight: normal;
}

.wrapper .container .wrapper .row .title span,
.wrapper .container .wrapper .dimension-wrapper .title span,
.wrapper .container .wrapper .metric-wrapper .title span,
.form-group .form-control {
  font-size: 14px;
}

.calendar-wrapper .weekdays .day,
.calendar-wrapper .days .week .day,
.dropdown-wrapper .contents ul.quick-selectors li,
.dropdown-wrapper .buttons .date-range,
.calendar-wrapper .month .month-text {
  font-size: 12px;
}

th.has-multi-choice {
  width: 44px !important;
}

.multi-choice {
  width: 44px !important;
  min-width: 0;

  position: relative;
  top: -2px;

  [indeterminate='true'] {
    margin-right: 0;
  }

  .mat-icon-button mat-icon {
    left: 0 !important;
  }

  .mat-icon-button {
    width: 24px !important;
  }
}

.mat-dialog-content [filter-search-box] {
  position: fixed !important;
}

.mat-mdc-select-trigger {
  min-width: 72px;
  width: 100%;
}

.form-group label span {
  display: inline-block;
  padding-left: 4px;
}

mat-dialog-container.mdc-dialog {
  overflow: visible !important;

  .mdc-dialog__surface {
    overflow: unset;
  }

  .mdc-dialog__title {
    padding: 0;
    margin: 0 0 20px;

    &::before {
      display: none;
    }
  }

  .mdc-dialog__content {
    padding: 0;
    line-height: normal;
    color: $font-color-1;
  }

  .mdc-dialog__actions {
    padding: 0;
  }
}

.mat-mdc-slide-toggle {
  .mdc-switch__icons {
    display: none;
  }
}

textarea::placeholder {
  font-size: 13px !important;
  color: $icon-color !important;
}

input[type='text']::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 13px !important;
  color: #b0b0b0;
}

input[type='text']:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 13px !important;
  color: #b0b0b0;
}

input[type='text']::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: 13px !important;
  color: #b0b0b0;
}

.table-list-container .table-list-container-main {
  position: relative;
}

.mat-tooltip {
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: $font-color-1;
  border-radius: 3px;
  background-color: $font-color-1;
}

.mat-menu-main-table {
  &.mat-menu-panel {
    min-height: 40px !important;

    .mat-menu-content:not(:empty) {
      padding: 0 !important;
    }

    .mat-menu-item {
      background-color: $bg-color-2;
      height: 40px;
      line-height: 40px;
      font-weight: 600;
      font-size: 13px;
    }
  }
}

.mat-input-element {
  box-sizing: border-box !important;
}

.carousel {
  z-index: 999 !important;
}

// .posts {
//   @mixin swiper-next() {
//     right: 0;
//     border-radius: 3px 0 0 3px !important;
//     color: black;
//     background: white;
//     width: 40px;
//     height: 100%;
//     top: 0;
//     margin-top: 0 !important;
//     &:after {
//       font-size: 24px;
//       font-weight: 600;
//     }
//   }

//   @mixin swiper-prev() {
//     left: 0;
//     border-radius: 0 3px 3px 0 !important;
//     color: black;
//     background: white;
//     width: 40px;
//     height: 100%;
//     top: 0;
//     margin-top: 0 !important;
//     &:after {
//       font-size: 24px;
//       font-weight: 600;
//     }
//   }
//   .story-next, .post-next {
//     @include swiper-next();
//   }
//   .story-prev, .post-prev {
//     @include swiper-prev();
//   }
// }

// new override for swiper
.swiper {
  overflow: clip;
}
.post-details-container {
  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: black;
    background: white;
    width: 24px;
    height: 48px;

    &:after {
      font-size: 24px;
      font-weight: 600;
    }
  }

  .swiper-paging-container {
    width: 100%;
  }

  .swiper-pagination {
    margin: 0 auto;
    left: 0;
    right: 0;
    width: fit-content !important;
    margin-bottom: 4px !important;
    background-color: $bg-color-2;
    border-radius: 8px;
  }

  // swiper-pagination-bullet swiper-pagination-bullet-active
  .swiper-pagination-bullet {
    border: 2px solid $status-12 !important;
    opacity: 0.4;
    background: $status-12;
    margin: 0 4px 1px !important;
  }

  .swiper-pagination-bullet-active {
    background-color: $status-12 !important;
    opacity: 1 !important;
  }
}

.story-item {
  .mat-mdc-icon-button {
    width: 16px;
    height: 16px;
    line-height: 16px;

    .mat-icon {
      width: 16px;
      min-width: 16px;
      min-height: 16px;
      height: 16px;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

// MAYBE a file for custom any lib like material
any-breadcrumb.overrided {
  ol.breadcrumb {
    margin: 0;
  }
}

.any-choice:checked,
.any-choice.checked,
.any-choice.form-check.checked {
  .form-check-label {
    color: $am-primary;
  }
  .form-check-input:checked {
    background-color: $am-primary !important;
    border-color: $am-primary !important;
  }
}
.any-choice.indeterminate {
  .form-check-label {
    color: $am-primary;
  }
}
.fieldset.chip-input {
  any-checkbox {
    .any-choice.form-check.unchecked {
      input.form-check-input {
        background-color: white !important;
        border-color: white !important;
      }
    }
  }
}


input[appngxdaterangepickermd] {
  background-color: $bg-color-2;
  color: $font-color-1;
}

// For More filter on report page.
.mat-dark-theme {
  button[anybutton].more-filter-button-active {
    background-color: rgba(56, 146, 229, 0.1) !important;
    color: $am-primary;
  }
}

.date-picker,
.mat-input-element {
  caret-color: $font-color-1 !important;
}

.date-config-row {
  position: absolute;
  bottom: -16px;
  right: 0;
}

.relative {
  position: relative;
}

// Highcharts override to default color tooltip
.highcharts-tooltip-box {
  fill: #303030;
}

.highcharts-tooltip {
  text {
    fill: #ffffff;
  }
}

.break-line {
  white-space: pre-line;
}

.mat-mdc-select-trigger {
  padding-left: 6px;
  padding-right: 12px;
  height: 100%;
}

.no-truncate-tooltip {
  word-break: break-all !important;
  white-space: normal !important;
}

.mat-error {
  color: $status-0 !important;
  font-size: 12px !important;
}

#guidelineMessage {
  font-size: 14px;
  color: $font-color-1;

  ol {
    margin: 8px 0;

    li {
      line-height: 20px;
    }
  }
}
// swiperjs
swiper-slide {
  width: fit-content;
}

.ng-select.ng-select-disabled .ng-select-container {
  background-color: $bg-disabled !important;
  color: $font-color-disabled !important;
}
