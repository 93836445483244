// This file is using to temporary fix style of any UI library.
// should check and remove the style when the any UI library is updated on new version.
@import 'variables/variables';
@import 'mixins/custom-btn';
// == FOR LIGHT MODE ==

// FOR LIGHT MODE
.any-switch.checked {
  background-color: $am-primary !important;
}

.any-switch {
  background-color: $border-color-1 !important;
}

// == FOR DARK MODE ==
.mat-dark-theme {
  // any button
  // any switch
  .any-switch.checked {
    background-color: $am-primary !important;
  }
}

// any breadcrumb
any-breadcrumb {
  .breadcrumb-item {
    span {
      color: $font-color-2 !important;
    }

    &.active span {
      color: $font-color-1 !important;
    }
  }
}

// FOR DARK MODE
.mat-dark-theme {
  button[anybutton].btn-outline-default {
    color: #c5d0da;

    &:hover,
    &:focus {
      background-color: #3d464f !important;
    }

    &:active {
      background-color: #525a62 !important;
    }

    &:disabled {
      background-color: #27313b !important;
      color: #56616b !important;
    }
  }

  div[anyButton].btn-outline-default {
    color: #c5d0da;
  }

  a[anyButton].btn-outline-default {
    color: #c5d0da;
  }

  button[anyButton].btn-primary {
    &:disabled,
    &.disabled {
      color: #6b839a !important;
      background-color: #2c4e6e !important;
      border-color: #2c4e6e !important;
    }
  }
}

any-tooltip.tooltip.show {
  opacity: 1;

  .tooltip-arrow::before {
    border-top-color: $bg-tooltip !important;
  }

  .tooltip-inner {
    background-color: $bg-tooltip !important;
    color: $font-color-tooltip !important;
  }
}

.any-switch {
  .any-switch-label {
    &.checked {
      color: $font-color-toggle-check !important;
    }

    &.unchecked {
      color: $font-color-toggle-uncheck !important;
    }
  }

  &.any-switch-sm {
    padding: 2px !important;
    margin-top: 0px !important;
  }

  &.any-switch-md {
    padding: 3px !important;
    margin-top: 0px !important;
  }

  &.any-switch-lg {
    padding: 4px !important;
    margin-top: 0px !important;
  }
}

any-switch {
  &.capitalize-label {
    .any-switch-label {
      text-transform: capitalize !important;
    }
  }
}

// Primary style
button[anyButton].btn-primary {
  font-size: 12px;
  color: #ffffff;
  background-color: $am-primary;
  border-color: transparent !important;
  line-height: 100% !important;

  &:hover,
  &:focus {
    background-color: #60a8ea;
  }

  &:active {
    background-color: #2d75b7;
  }

  &:disabled {
    color: $font-btn-disabled !important;
    background-color: $bg-btn-disabled !important;
    border-color: $border-color-1 !important;
  }

  .mat-icon-16 {
    color: white !important;
  }
}

// Outline default style
button[anyButton].btn-outline-default {
  font-size: 12px;
  color: $font-color-2;
  border-color: $border-color-1;
  background-color: $bg-color-2;
  line-height: 100% !important;

  &:hover {
    color: $font-color-1;
    background-color: $bg-color-2 !important;
    border-color: $border-color-4 !important;
  }

  &:active {
    color: $font-color-1;
    background: $bg-color-1 !important;
    border-color: $border-color-4 !important;
  }

  &:focus {
    color: $font-color-1;
    border-color: $border-color-4 !important;
    box-shadow: none !important;
  }

  &.disabled,
  &:disabled {
    color: $font-btn-disabled !important;
    background-color: $bg-btn-disabled !important;
    border-color: $border-color-1 !important;
  }

  &.btn-sm {
    font-weight: 600;
    letter-spacing: 0.2px;
  }
}

// anyButton utilities
.btn {
  padding: 9px 24px !important;
  font-weight: 600 !important;
  line-height: 1 !important;

  &[anyButton] {
    font-size: 12px;
  }

  &.btn-primary {
    padding: 9px 24px !important;
    line-height: 1 !important;
    box-shadow: none !important;
  }
}

.btn-success {
  font-size: 12px !important;
  padding: 8px 24px !important;
}

.btn.btn-danger {
  font-size: 12px !important;
  line-height: 1 !important;
  padding: 9px 24px !important;
  font-weight: 600 !important;
}

.btn-sm {
  padding: 5px 16px !important;
  font-weight: 600 !important;
}

.mat-icon-16 {
  @include icon-size();
}

.mat-icon-24 {
  @include icon-size(24px);
}

button[anyButton] .mat-icon-16 {
  @include icon-size();
}
.mat-dark-theme {
  button[anyButton].btn-mat-icon-only-no-border {
    .mat-icon {
      color: #c5d0da !important;
    }
    &.disabled,
    &:disabled {
      .mat-icon {
        color: #6E7C89 !important;
      }
    }
  }
}
button[anyButton].btn-mat-icon-only-no-border {
  padding: 0 !important;
  border: 0px !important;
  box-shadow: none !important;
  background-color: transparent !important;
  .mat-icon {
    color: $font-color-2;
  }
  &:hover,
  &:focus {
    .mat-icon {
      color: $font-color-1 !important;
    }
  }

  &:active {
    .mat-icon {
      color: $font-color-1 !important;
    }
  }

  &.disabled,
  &:disabled {
    .mat-icon {
      color: $font-btn-disabled !important;
    }
  }
}
button[anyButton].btn-mat-icon-only.btn-outline-default {
  padding: 7px !important;

  .mat-icon {
    color: $font-color-2;
  }

  &:hover,
  &:focus {
    border-color: $border-color-4;
    box-shadow: none !important;

    .mat-icon {
      color: $font-color-1 !important;
    }
  }

  &:active {
    background: $bg-color-1 !important;
    border-color: $border-color-4;

    .mat-icon {
      color: $font-color-1 !important;
    }
  }

  &.disabled,
  &:disabled {
    .mat-icon {
      color: $font-btn-disabled !important;
    }
  }
}

button[anyButton].btn-mat-icon {
  padding: 7px 16px 7px 8px !important;

  &.icon-right {
    padding: 7px 8px 7px 16px !important;
  }
  &.icon-left {
    padding: 7px 16px 7px 8px !important;
  }

  .mat-icon {
    color: $font-color-2;
  }

  &.btn-primary,
  &.btn-outline-primary {
    &:hover,
    &:focus {
      box-shadow: none !important;

      .mat-icon {
        color: $font-color-2 !important;
      }
    }
  }

  &:hover,
  &:focus {
    box-shadow: none !important;

    .mat-icon {
      color: $font-color-1 !important;
    }
  }

  &:active {
    .mat-icon {
      color: $font-color-1 !important;
    }
  }

  &.disabled,
  &:disabled {
    .mat-icon {
      color: $font-btn-disabled !important;
    }
  }
}

button[anyButton].btn-header-bg-white:not(:disabled) {
  background-color: $bg-color-2;
}

button[anyButton].btn-outline-danger,
button[anyButton].btn-danger {
  background-color: #e53838 !important;
  color: white;
  border-radius: 3px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  /* 12px */
  letter-spacing: 0.2px;

  &:hover,
  &:focus {
    outline: none !important;
    background-color: #ff5575 !important;
    box-shadow: none !important;
  }

  &:active {
    outline: none !important;
    box-shadow: none !important;
    background-color: #cc2242 !important;

    .mat-icon {
      color: $font-color-1 !important;
    }
  }

  &.disabled,
  &:disabled {
    color: $font-btn-disabled !important;
    background-color: $bg-btn-disabled !important;
    border-color: $border-color-1 !important;

    .mat-icon {
      color: $font-btn-disabled !important;
    }
  }
}

// For More filter on report page.
.mat-dark-theme {
  button[anybutton].more-filter-button-active {
    background-color: rgba(56, 146, 229, 0.1) !important;
    color: $am-primary !important;
  }
}

any-checkbox {
  .form-check-input[type='checkbox']:indeterminate {
    background-color: $am-primary !important;
    border-color: $am-primary !important;
  }

  .form-check-input[type='checkbox'] {
    margin-top: 0;
  }
}

any-radio-group {
  .any-choice .form-check-input {
    margin-bottom: 3px;
  }
}

// any-radio
.any-radio-group-am-style {
  display: flex;

  any-radio-group > div > div {
    flex-direction: row !important;
    gap: 16px;
  }

  &.vertical {
    any-radio-group > div > div {
      flex-direction: column !important;
      gap: 16px;
    }
  }
}
