@import 'node_modules/bootstrap/scss/tooltip';

@mixin tooltip-extension() {
  .tooltip {
    pointer-events: none;
    position: relative;

    .tooltip-inner {
      pointer-events: auto;
    }

    &.bs-tooltip-top .tooltip-arrow,
    &.bs-tooltip-bottom .tooltip-arrow {
      transform: translate(-50%, 0);
      left: 50%;
    }

    &.bs-tooltip-end .tooltip-arrow,
    &.bs-tooltip-start .tooltip-arrow {
      transform: translate(0, -50%);
      top: 50%;
    }
  }
}

@include tooltip-extension();
