@use 'sass:list';

%sm-switch-font-size {
  font-size: $switch-font-size-sm;
}
%md-switch-font-size {
  font-size: $switch-font-size-md;
}
%lg-switch-font-size {
  font-size: $switch-font-size-lg;
}

@mixin config-switch-sizes($prefix, $sizes...) {
  @each $i in $sizes {
    &.#{$prefix}#{list.nth($i, 1)} {
      min-width: list.nth($i, 2) * 3;
      height: list.nth($i, 2);
      padding: list.nth($i, 2) * 0.2;
      border-radius: list.nth($i, 2);

      small {
        right: calc(100% - #{list.nth($i, 2)});
        width: list.nth($i, 2);
        height: list.nth($i, 2);
      }

      > .any-switch-pane {
        > span {
          @extend %#{ list.nth($i, 1) }-switch-font-size;

          line-height: list.nth($i, 2);
        }

        .any-switch-label {
          color: $white;
          font-weight: $font-weight-bold;
          text-transform: uppercase;

          &.checked {
            padding-right: list.nth($i, 2) + 0.25rem;
            padding-left: 0;
          }

          &.unchecked {
            padding-left: list.nth($i, 2) + 0.25rem;
            padding-right: 0;
          }
        }
      }
    }
  }
}

@mixin switch-extension() {
  .any-switch {
    background-color: $gray-400;
    border: $switch-border;
    position: relative;
    display: inline-block;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: 0.3s ease-out all;
    white-space: nowrap;

    small {
      background-color: $gray-200;
      border-radius: 100%;
      position: absolute;
      top: 0;
      transition: 0.3s ease-out all;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include config-switch-sizes(
      'any-switch-',
      'sm' $switch-knob-size-sm,
      'md' $switch-knob-size-md,
      'lg' $switch-knob-size-lg
    );

    .any-switch-pane {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 100%;
      justify-content: flex-start;
      align-items: center;
      top: 0;
      position: relative;
      pointer-events: none;

      > span {
        display: block;
        min-height: 100%;
      }

      .any-switch-label {
        &.checked {
          // opacity: 0;
          display: none;
        }

        &.unchecked {
          // opacity: 1;
          display: block;
        }
      }
    }

    &:active {
      small {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 40%);
      }
    }

    &.checked {
      background-color: $blue;

      small {
        right: 0;
        left: auto;
      }

      .any-switch-pane {
        top: 0;

        .any-switch-label {
          &.checked {
            // opacity: 1;
            display: block;
          }

          &.unchecked {
            // opacity: 0;
            display: none;
          }
        }
      }
    }

    &.disabled {
      opacity: 50%;
      cursor: not-allowed;
    }

    &.loading {
      background-color: $gray-200;

      small {
        background-color: transparent;
        border: none;
        box-shadow: none;
        right: 50%;
        transform: translateX(50%);
      }
    }
  }
}

@include switch-extension();
