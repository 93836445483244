@import 'variables/variables';

/**
* This file contain mixin style for typo.
*/

@mixin text-dark($size, $weight) {
  font-family: Inter;
  font-weight: $weight;
  font-size: $size;
  line-height: normal;
  color: $font-color-1;
}

@mixin text-dark-grey($size, $weight) {
  font-family: Inter;
  font-weight: $weight;
  font-size: $size;
  line-height: normal;
  color: $font-color-2;
}

@mixin text-error($size, $weight) {
  font-family: Inter;
  font-weight: $weight;
  font-size: $size;
  line-height: normal;
  color: #ff5f5f;
}

@mixin text-info($size, $weight) {
  font-family: Inter;
  font-weight: $weight;
  font-size: $size;
  line-height: normal;
  color: $status-13;
}

@mixin text-primary($size, $weight) {
  font-family: Inter;
  font-weight: $weight;
  font-size: $size;
  line-height: normal;
  color: $am-primary;
}
