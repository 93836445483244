
.clicker {
  padding: 11px 0 !important;
  height: 30px !important;
  cursor: pointer;

  .text {
    font-size: 12px;
    position: relative;
    top: -4px;
  }

  .icon {
    position: relative;
    top: -5px;
  }
}