@import 'variables/variables';

mat-expansion-panel {
  .mat-content {
    width: auto;
  }
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
}

.mat-expansion-panel-header:not([aria-disabled='true']) {
  &:hover {
    background: none !important;
  }
}

.mat-expansion-panel-header {
  padding: 0 !important;

  .mat-expansion-panel-header-title {
    color: $am-primary;
  }
}

mat-expansion-panel-header {
  .mat-content {
    display: inline-block;
    padding: 20px 0px;
    flex: none;
    overflow: initial;
    cursor: pointer;
  }
}

.site-expansion-panel {
  .mat-expansion-panel-header {
    justify-content: space-between;
    margin: 0 16px;

    .mat-content .mat-expansion-panel-header-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      color: $font-color-1;
      border-right: 1px solid $border-color-1;
      height: 24px;
    }
    .mat-expansion-indicator {
      font-size: 16px;
    }
    .mat-expansion-panel-header-description,
    .mat-expansion-indicator::after {
      color: $am-primary;
    }

    .mat-content {
      width: calc(100% - 16px);
    }
  }

  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    background: $bg-color-2;
    border: 1px solid $border-color-1;
    box-sizing: border-box;
    box-shadow: none !important;
  }

  .mat-expansion-panel-content {
    border-top: 1px solid $border-color-1;
  }
}

.mat-expansion-panel.custom-expansion-panel {
  border: 1px solid $border-color-1;

  .mat-expansion-panel-header {
    display: flex;
    justify-content: space-between;
    padding: 0 16px !important;

    &.mat-expanded {
      height: 48px;
      transition: none;
    }

    .mat-content {
      flex-grow: 1;

      .mat-expansion-panel-header-title {
        color: $font-color-1 !important;
        border-right: 1px solid $border-color-1;
      }
    }
  }

  .mat-expansion-panel-body {
    border-top: 1px solid $border-color-1;
    padding: 16px 24px;
  }
}
