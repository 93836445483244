@use '@angular/material' as mat;
@use 'shared/highchart-custom';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'variables/variables';
@import url('https://rsms.me/inter/inter.css');

// Perfect scrollbar
//@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import 'core/adpp';
// Theming
@include mat.core();
// @include mat.core($custom-typography);
// Include theme styles for core and each component used in your app.
// @include mat.core-theme($light-theme);
@include mat.all-component-themes($light-theme);
// @include mat.all-component-colors($light-theme);
// @include mat.all-component-typographies($custom-typography);

.mat-dark-theme {
  // @include mat.core-color($dark-theme);
  @include mat.all-component-colors($dark-theme);
}

//// Partials
@import 'partials/reset';
@import 'partials/normalize';
@import 'partials/scrollbars';
@import 'partials/helpers';
// @import "partials/global";
@import 'partials/icons';
// @import "partials/colors";
// @import "partials/material";
@import 'partials/angular-material-fix';
@import 'partials/any-library-fix';
@import 'partials/typography';
@import 'partials/forms';
@import 'partials/toolbar';
@import 'partials/print';
@import 'partials/ngx-editor';
@import 'partials/tooltip';
@import 'partials/dragular';
@import 'partials/notification';

// Plugins
@import 'partials/plugins/plugins';

// Custom scss
@import 'core/libs/ng2-toastr.min';
@import 'core/libs/ng2-dragula';
//@import "../components/toast/toast.component";
@import 'core/grid';
@import 'core/table';
@import 'core/util';
@import 'core/override';
@import 'core/spinner';
@import 'core/datepicker';
@import 'core/accordion';
@import 'core/ngx_toastr';
@import 'shared/custom-snackbar';
@import 'core/exterior-stepper';
@import 'shared/custom_toolbar_detail.scss';
@import 'shared/highchart-custom.scss';
@import 'core/icon';
@import 'core/markdown';

@layer base {
  ul,
  ol {
    list-style: revert;
    margin: revert;
    padding: revert;
  }

  h3 {
    margin: revert;
  }

  table {
    border-collapse: revert;
  }
}

@import 'core/disabled-setting';
@import 'gridstack/dist/gridstack.min.css';
@import 'gridstack/dist/gridstack-extra.min.css';
