/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
  .mapping-list-item {
    .name-col {
      flex: 1 1 calc(30% - 16px);
      box-sizing: border-box;
      min-width: calc(30% - 16px);
      margin-right: 16px;
    }
    .searchkey-col {
      flex: 1 1 calc(50% - 16px);
      box-sizing: border-box;
      min-width: calc(50% - 16px);
    }
    .imp-col {
      flex: 1 1 calc(20% - 16px);
      box-sizing: border-box;
      min-width: calc(20% - 16px);
      margin-right: 0;
      justify-content: flex-end;
      display: flex;
    }
    .mat-expansion-panel-body {
      background-color: black !important;
    }
    margin: 16px;
    border: 1px solid $border-color-1;
    padding: 0 16px;
    .content-row {
      display: flex;
      padding-bottom: 16px;
      .searchkey-col {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-left: 500px;
      }
    }
  }

  .selected-mediation-item {
    border: 1px solid $border-color-1;
    padding: 8px 0px 8px 8px;
    display: flex;
    justify-content: space-between;
    height: 48px;
    border-radius: 3px;
    padding-bottom: 4px;
    .item-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: default;
      padding-right: 8px;
    }
    .icon-controls {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .mock-icon-btn {
        padding: 4px !important;
        height: 24px;
        width: 24px;
        border-left: 1px solid $border-color-1;
        &.border-bottom {
          border-bottom: 1px solid $border-color-1;
        }
      }
    }
  }
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
  &.selected-mediation-item-list {
    margin-bottom: 12px;
    opacity: 0.45;
    .selected-mediation-item {
      border: 1px solid $border-color-1;
      padding: 8px 0px 8px 8px;
      display: flex;
      justify-content: space-between;
      height: 48px;
      border-radius: 3px;
      margin: 16px;
      background-color: $bg-color-1;
      .item-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: default;
        padding-right: 8px;
      }
      .icon-controls {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mock-icon-btn {
          padding: 4px !important;
          height: 24px;
          width: 24px;
          border-left: 1px solid $border-color-1;
          &.border-bottom {
            border-bottom: 1px solid $border-color-1;
          }
        }
      }
    }
  }
}

.group-data {
  .gu-transit {
    &.mat-expansion-panel {
      border: 1px solid $border-color-1;
      padding: 0 8px;
      margin: 8px 0;
      .mat-expansion-panel-header {
        .mat-expansion-panel-header-description {
          display: none !important;
        }
      }
    }
    &.mat-expanded {
      .searchkey-col, .mat-expansion-panel-content {
        display: none !important;
      }
      .panel-title-col.animate-header.center-item {
        transform: translateX(0) !important;
      }
    }
  }
}