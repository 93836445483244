@use 'variables/variables';

.toolbar {
  .toolbar-separator {
    height: 48px;
    width: 1px;
    border-right: 1px solid $font-color-1;
    margin: 0 12px;
  }
}
.toolbar-title {
  font-family: Inter, sans-serif !important;
  font-size: 18px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: $font-color-1;
}

.app-filter-header {
  width: 240px;
  font: 400 13px/16px Inter !important;
  color: $font-color-1;
  ng-select {
    margin-top: -8px;
    &:focus {
      outline: none;
    }
  }
  .ng-select.adpp-custom .ng-select-container {
    height: 32px;
    min-height: unset !important;
  }
}
