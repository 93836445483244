@import 'node_modules/bootstrap/scss/breadcrumb';

@mixin breadcrumb-extensions() {
  .breadcrumb-item {
    a,
    span {
      color: $breadcrumb-link-color;
      text-decoration: none;
    }
    &.active {
      a,
      span {
        color: $breadcrumb-active-color;
        font-weight: $font-weight-bold;
      }
    }
  }
}

@include breadcrumb-extensions();
