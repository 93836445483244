@use 'variables/variables';
@import 'mixins/custom-btn';

$size-16: 16px;

.mat-dark-theme {
  i,
  mat-icon {
    color: $icon-color !important;
  }
}

i,
mat-icon {
  color: $font-color-2;
  font-size: 24px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  line-height: 24px;

  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      @for $size from 2 through 128 {
        &.s#{$infix}-#{$size * 2} {
          font-size: #{($size * 2) + 'px'} !important;
          width: #{($size * 2) + 'px'} !important;
          height: #{($size * 2) + 'px'} !important;
          min-width: #{($size * 2) + 'px'} !important;
          min-height: #{($size * 2) + 'px'} !important;
          line-height: #{($size * 2) + 'px'} !important;
        }
      }
    }
  }
}


// FOR MENU ICONS
.side-nav-parent-item,
.side-nav-item {
  .mat-icon {
    &.icon-active {
      path,
      rect,
      circle,
      ellipse {
        fill: $am-primary !important;
      }

      &[data-mat-icon-name='menu_group_payment_process'] {
        rect {
          fill: #aed3f5 !important;
        }
      }
    }

    // Custom for some specific icon
    &.payment-icon {
      rect {
        fill: #c5d0da !important;
      }
    }

    &.speed-icon {
      &.icon-active {
        path:nth-child(2),
        path:nth-child(4) {
          fill: #99c0e5 !important;
        }
      }
    }

    &.help-page-icon {
      &.icon-active {
        path,
        circle:nth-child(2) {
          fill: #aed3f5 !important;
        }
      }
    }
    &.ai-assistant-icon {
      &.icon-active {
        path:nth-child(1) {
          fill: #aed3f5 !important;
        }
      }
    }
  }

  &:hover {
    .mat-icon {
      path,
      rect {
        fill: $font-color-1;
      }

      // Custom for some specific icon
      &.speed-icon {
        path:nth-child(2),
        path:nth-child(4) {
          fill: #c5d0da !important;
        }
      }

      &.help-page-icon {
        path,
        circle:nth-child(2) {
          fill: #686f75 !important;
        }

        circle:nth-child(1) {
          fill: #27313b !important;
        }
      }

      &.help-page-icon {
        &.icon-active {
          path,
          circle:nth-child(2) {
            fill: #aed3f5 !important;
          }

          circle:nth-child(1) {
            fill: $am-primary !important;
          }
        }
      }

      &[data-mat-icon-name='invoice'] {
        rect {
          fill: white;
        }

        path:nth-child(1) {
          fill: black;
        }
      }

      &[data-mat-icon-name='menu_group_payment_process'] {
        path {
          fill: #27313b;
        }

        rect {
          fill: #cdd3d8;
        }
      }

      // settings_mapping
      &[data-mat-icon-name='settings_mapping'] {
        ellipse,
        circle {
          fill: $font-color-1 !important;
        }
      }
      &[data-mat-icon-name='menu_group_ai_assistant'] {
        path:nth-child(1) {
          fill: #27313b;
        }
        path:nth-child(2) {
          fill: #6E7C89;
        }
      }
    }
  }
}

.mat-dark-theme {
  .side-nav-parent-item,
  .side-nav-item {
    .mat-icon {
      &[data-mat-icon-name='invoice'] {
        path {
          fill: white;
        }
      }
    }

    &:hover {
      .mat-icon {
        &[data-mat-icon-name='payment_invoice'] {
          path:nth-child(1) {
            fill: $font-color-1;
          }
        }

        &[data-mat-icon-name='menu_group_payment_process'] {
          path {
            fill: white;
          }

          rect {
            fill: #686f75;
          }
        }

        &.help-page-icon {
          path,
          circle:nth-child(2) {
            fill: #686f75 !important;
          }

          circle:nth-child(1) {
            fill: white !important;
          }
        }
      }
    }
  }
}

#theme-switcher {
  .theme-selection {
    .mat-icon {
      &[data-mat-icon-name='light_theme_active'] {
        height: 17px !important;
      }
    }
  }
}

// FOR DASHBOARD CARD ICONS
.accordion-icon {
  .header-mat-icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    line-height: 16px;

    &.icon-24 {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      line-height: 24px;
    }

    &:hover {
      path {
        stroke: $font-color-1;
        fill: transparent !important;
      }

      &.mat-icon {
        path,
        circle {
          fill: $font-color-1;
          stroke: $font-color-1;
        }
      }
    }
  }
}

// FOR CWV vector
.mat-dark-theme {
  .vector-icon {
    .vector-mat-icon {
      &.mat-icon {
        path {
          fill: white;
        }
      }
    }
  }
}

// FOR SNS
.mat-dark-theme {
  .sns-header-icons {
    .overview.mat-icon path {
      fill: $font-color-1;
    }

    .audience.mat-icon path {
      fill: $font-color-1;
    }

    .posts.mat-icon path {
      fill: $font-color-1;
    }
  }
}

// FOR maintain page
#maintainPage,
.error-pages {
  .warning-icon {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    font-size: 48px;
  }
}
