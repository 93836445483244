// *************************************************************************************************
// This file will be use to update share var of bg/font/etc color
// Here override AnyUi and Bootstrap variables
// *************************************************************************************************

$theme-colors: (
  'primary': #3892e5,
  'secondary': #27313b,
  'success': #40b87c,
  'info': #3892e5,
  'warning': #ffb63d,
  'danger': #ff5f5f,
  'default': #6e7c89,
  'negative': #ff2b52,
  'neutral': #ffc876,
  'white': #ffff,
);
$font-size-base: 0.875rem !default;
// layout px variables
$spacing-8: 8px;
$spacing-16: 16px;
$spacing-24: 24px;
$spacing-32: 32px;

// typo variables
$font-size-page-title: 18px;

// light theme by default (1=darker, 2=ligher)
:root {
  --am-primary: #3892e5; // (NOTE: constant color): primary color of AM

  --bg-color-1: #f6f8fa; // bg for the background of card/rendered page - darker
  --bg-color-2: #ffffff; // bg for card/menu item/container/etc - lighter
  --bg-disabled: #f6f8fa; // use for disable bg. filter select
  --bg-tooltip: #1e262e;
  --bg-btn-disabled: #f6f8fa; // use for bg of disabled button
  // status colors
  --status-0: #ff5f5f; // red - for display error in general
  --status-1: #ffcfcf; // light-red
  --status-2: #ffe9c5; // light-yellow
  --status-3: #c6ead8; // green
  --status-4: #c7f1f4; // mint-blue
  --status-5: #c3def7; // blue
  --status-6: #ebc6f8; // purple
  --status-7: #ffe1f5; // pink
  --status-8: #d4d8dc; // grey|black
  --status-9: #40b87c; // green - for display success in general
  --status-10: #ffc876; // yellow - for neutral status
  --status-11: #ffb63d; // yellow - for warning
  --status-12: #ff2b52; // red - for negative/warning info
  --status-13: #7aabf5; // blue - for positive info
  --status-14: #ff8383; // red - for negative info but lighter
  --status-15: #ff5f5f; // new red 12-2023 - for negative/warning info (replace status-12)

  --border-color-1: #dee5ec; // use for border and some bg - darker
  --border-color-2: #eef3f7; // use for border and some bg - ligher
  --border-color-3: #dddddd;
  --border-color-4: #c5d0da;

  --font-color-1: #27313b; // text - darker
  --font-color-2: #6e7c89; // (NOTE: constant color): text grey - lighter
  --font-color-3: #27313b;
  --font-color-toggle-uncheck: #a8b4bf;
  --font-color-toggle-check: #ffffff;
  --font-color-disabled: #dee5ec; // use only for text, not use for button, card, etc
  --font-color-dislabed-2: #dee5ec; // use for text with white bg
  --font-btn-disabled: #a8b4bf; // use for text of disabled button
  --font-color-status: #27313b; //  - status color text is fixed with dark text
  --font-color-tooltip: #ffffff;
  --font-color-parent-menu: #27313b; // use for text of parent menu item
  --font-color-nav-item: #6E7C89;
  --bg-menu-item: #e1effb; // use for bg of menu-item and any item using primary with opacity
  --icon-color: #c5d0da;
  --bg-theme-switcher: #FFFFFF;

  --box-shadow: 0px 2px 1px #6e7c890d, 0px 0px 2px #6e7c8940; //default box-shadow 5/25%
  --box-shadow-bottom: 0px 2px 0px #6e7c890d; // only create shadow for bottom
  --box-shadow-menu: 0px 1px 3px #0000000d; // only using on side menu item.

  // any ui library
  --any-gray-200: #f6f8fa;
  --any-white: #ffffff;
  --any-gray-400: #dee5ec;
  --any-gray-300: #eef3f7;
  --any-gray-900: #27313b;
  --any-gray: #6e7c89;
  // material
  --mdc-dialog-container-color: #ffffff;
  --mdc-theme-surface: #ffffff;
  --mat-icon-color: #6e7c89;
  --mdc-plain-tooltip-container-color: #ffffff;
  --mat-paginator-container-text-color: #27313b;
  --mat-table-row-item-container-height: 40px; // modify default height of mat-table row
  --fixed-width: 984px;
}

// dark theme contrast
// #3892E5; -> #3892E5;
// #6E7C89; -> #6E7C89;
// #E1EFFB; -> #3892e54d;
// ====================
// #FFFFFF; -> #27313A;
// #F6F8FA; -> #1E262E;
// #27313B; -> #FFFFFF;
// #DEE5EC; -> #47505A;
// #EEF3F7; -> #404C58;
// #C5D0DA; -> #C5D0DA;
// #DDDDDD; -> #3A4958;
$am-primary: var(--am-primary);
$bg-color-1: var(--bg-color-1);
$bg-color-2: var(--bg-color-2);
$bg-disabled: var(--bg-disabled);
$border-color-1: var(--border-color-1);
$border-color-2: var(--border-color-2);
$border-color-3: var(--border-color-3);
$border-color-4: var(--border-color-4);
$font-color-1: var(--font-color-1);
$font-color-2: var(--font-color-2);
$font-color-3: var(--font-color-3);
$font-color-toggle-uncheck: var(--font-color-toggle-uncheck);
$font-color-toggle-check: var(--font-color-toggle-check);
$font-color-disabled: var(--font-color-disabled);
$font-color-parent-menu: var(--font-color-parent-menu);
$font-color-nav-item: var(--font-color-nav-item);
$bg-menu-item: var(--bg-menu-item);
$icon-color: var(--icon-color);
$box-shadow: var(--box-shadow);
$box-shadow-bottom: var(--box-shadow-bottom);
$box-shadow-menu: var(--box-shadow-menu);
$font-btn-disabled: var(--font-btn-disabled);
$bg-btn-disabled: var(--bg-btn-disabled);
// These var is fixed on both theme - not bring to the theme.color.ts yet.
$font-color-status: var(--font-color-status);
$status-0: var(--status-0);
$status-1: var(--status-1);
$status-2: var(--status-2);
$status-3: var(--status-3);
$status-4: var(--status-4);
$status-5: var(--status-5);
$status-6: var(--status-6);
$status-7: var(--status-7);
$status-8: var(--status-8);
$status-9: var(--status-9);
$status-10: var(--status-10);
$status-11: var(--status-11);
$status-12: var(--status-12);
$status-13: var(--status-13);
$status-14: var(--status-14);
$status-15: var(--status-15);
$bg-tooltip: var(--bg-tooltip);
$font-color-tooltip: var(--font-color-tooltip);
$mat-icon-color: var(--mat-icon-color);
$--mat-table-row-item-container-height: var(--mat-table-row-item-container-height);
$bg-theme-switcher: var(--bg-theme-switcher);