// *************************************************************************************************
//  BUTTONS https://getbootstrap.com/docs/5.1/components/buttons/#mixins
// *************************************************************************************************
@import 'node_modules/bootstrap/scss/buttons';

@mixin button-extension() {
  .btn {
    position: relative;

    &:disabled,
    &.disabled {
      pointer-events: all;
      cursor: not-allowed;

      span {
        pointer-events: none;
      }
    }
  }

  @each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
      @include button-outline-variant(
        $value,
        darken($value, 30%),
        lighten(darken($value, 10%), 16%),
        darken(lighten($value, 30%), 10%),
        $white
      );

      &:hover:not(:active):not(:disabled) {
        border-color: darken(lighten($value, 30%), 10%);
        background-color: $white;
      }

      &.btn-tab-focused {
        box-shadow: 0 0 0 0.1rem rgba($value, 50%);
      }

      &:disabled {
        border-color: $gray-400;
        background-color: $gray-400;
        color: $white;
      }
    }

    .btn-#{$color} {
      @include button-variant(
        $value,
        $value,
        $white,
        lighten($value, 20%),
        lighten($value, 20%),
        $white,
        darken($value, 20%),
        darken($value, 20%),
        $white,
        $gray-400,
        $gray-400,
        $white
      );

      &:active:not(:disabled) {
        background-color: darken($value, 20%);
        border-color: rgba($value, 50%);
      }

      &:focus:not(:hover) {
        background-color: $value;
        border-color: rgba($value, 50%);
      }

      &.btn-tab-focused {
        box-shadow: 0 0 0 0.1rem rgba($value, 50%);
      }
    }

    .btn-dismiss {
      @include button-variant(
        $white,
        $gray-400,
        $gray-600,
        $white,
        $gray-500,
        $gray-900,
        $gray-200,
        $gray-500,
        $gray-900,
        $gray-400,
        $gray-400,
        $white
      );

      &.btn-tab-focused {
        box-shadow: 0 0 0 0.1rem rgba($gray-500, 50%);
      }
    }
  }
}

@include button-extension();
