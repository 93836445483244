@use 'sass:map';
@import 'variables/variables';
@import 'https://code.highcharts.com/css/highcharts.css';

.highchart-sns-tooltip {
  background-color: #27313b;
  fill: #27313b !important;
  stroke: #27313b !important;
  color: #fff !important;
  font-size: 13px;
  line-height: 18px;
  z-index: 9999;
}

.highchart-sns-tooltip > :first-child {
  background-color: #27313b !important;
}

$chart-colors: (
  0: #3892e5,
  1: #ffb63d,
  2: #20c1e4,
  3: #8de336,
  4: #ff900d,
  5: #1bdfbc,
  6: #b584e5,
  7: #ffdc25,
  8: #ff8d8d,
);

#container {
  margin: 0 auto;
}

.highcharts-axis-labels {
  text {
    color: $font-color-1 !important;
    fill: $font-color-1 !important;
  }

  span {
    div {
      color: $font-color-1;
    }
  }
}

.highcharts-axis.highcharts-xaxis {
  .highcharts-axis-line {
    stroke: $border-color-1;
  }
}

.highcharts-grid.highcharts-yaxis-grid {
  path {
    stroke: #dee5ec;
  }
}

.long-dash-chart {
  .highcharts-grid.highcharts-yaxis-grid {
    path {
      stroke-dasharray: 8, 3;
      stroke-width: 1;
    }
  }
}

.highcharts-background {
  background-color: $bg-color-2;
  fill: $bg-color-2;
}

.highcharts-legend-item {
  text {
    fill: $font-color-1 !important;
  }

  &.highcharts-column-series rect.highcharts-point {
    stroke: $bg-color-2;
  }
}

.highcharts-pie-series .highcharts-point {
  stroke: $bg-color-2;
}

.highcharts-series.highcharts-bar-series.highcharts-tracker rect {
  stroke: $bg-color-2;
}

.mat-dark-theme {
  .highcharts-grid.highcharts-yaxis-grid {
    path {
      stroke: #333f49;
    }
  }
}

// chart color
@for $i from 0 through 9 {
  // line
  .highcharts-spline-series,
  .highcharts-line-series {
    // point color
    .highcharts-point.highcharts-color-#{$i} {
      fill: $bg-color-2;
      stroke: map.get($chart-colors, $i);

      &.highcharts-point-hover {
        fill: map.get($chart-colors, $i);
        stroke: $bg-color-2;
      }
    }

    // line color
    &.highcharts-color-#{$i} {
      .highcharts-graph {
        stroke: map.get($chart-colors, $i);
      }
    }
  }

  // column
  .highcharts-column-series {
    .highcharts-point.highcharts-color-#{$i} {
      fill: map.get($chart-colors, $i);
      stroke: $bg-color-2;
    }
  }

  .highcharts-bar-series.highcharts-color-#{$i} {
    .highcharts-point.highcharts-color-#{$i} {
      fill: map.get($chart-colors, $i);
      stroke: map.get($chart-colors, $i);
    }
  }

  .highcharts-scatter-series.highcharts-color-#{$i} {
    .highcharts-color-#{$i} {
      fill: map.get($chart-colors, $i);
      stroke: map.get($chart-colors, $i);
    }
  }

  // TODO: pie chart
}

// legend color
@for $i from 0 through 9 {
  .highcharts-legend-item {
    // line
    &.highcharts-spline-series.highcharts-color-#{$i},
    &.highcharts-line-series.highcharts-color-#{$i} {
      .highcharts-graph {
        stroke: map.get($chart-colors, $i);
      }

      .highcharts-point {
        fill: $bg-color-2;
        stroke: map.get($chart-colors, $i);
      }
    }

    // column
    &.highcharts-column-series.highcharts-color-#{$i} {
      .highcharts-point {
        fill: map.get($chart-colors, $i);
      }
    }

    // scatter highcharts-scatter-series highcharts-color-0
    &.highcharts-scatter-series.highcharts-color-#{$i} {
      .highcharts-graph {
        stroke: map.get($chart-colors, $i);
      }

      .highcharts-point {
        fill: map.get($chart-colors, $i);
        stroke: map.get($chart-colors, $i);
      }
    }

    // TODO: pie/bar chart
  }
}

.highcharts-reset-zoom {
  .highcharts-button-box {
    stroke: $border-color-1 !important;
    fill: $bg-color-2 !important;
  }

  text {
    fill: $font-color-2 !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
  }

  &:hover {
    text {
      fill: $font-color-1 !important;
    }
  }
}

.mat-dark-theme {
  .highcharts-reset-zoom {
    text {
      fill: $icon-color !important;
    }

    &:hover {
      text {
        fill: $font-color-1 !important;
      }
    }
  }
}
