@import "variables/variables";

.chart-icon-info {
  margin-left: 11px;
  position: relative;
  bottom: -2px;

  .icon {
    color: $icon-color;
    font-size: 18px;
  }

  > span {
    position: absolute;
    top: -28px;
    left: 30px;
    padding: 16px;
    color: $font-color-tooltip !important;
    background-color: $bg-tooltip;
    box-shadow: $box-shadow;
    width: 280px;
    height: auto;
    border-radius: 4px;
    z-index: 99999;

    display: none;
    opacity: 0;
  }

  &:hover > span {
    opacity: 1;
    display: inline-block;
  }
}

.icon-info {
  margin-left: 5px;

  .icon {
    color: $icon-color;
    position: relative !important;
    font-size: 13px;
  }

  >span {
    position: absolute;
    padding: 10px;
    color: $font-color-tooltip !important;
    background-color: $bg-tooltip;
    box-shadow: $box-shadow;
    max-width: 280px;
    height: auto;
    border-radius: 4px;
    font-size: 11px;
    z-index: 99999;

    display: none !important;
    opacity: 0;
  }

  &:hover>span {
    opacity: 1;
    display: inline-block !important;
  }
}