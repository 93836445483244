// This file is contain custom style for exterior stepper - may be using for other component in the future.
@import 'variables/variables';

.exterior-stepper {
  .mat-stepper-horizontal-line {
    max-width: 40px;
    width: 40px;
    min-width: 40px;
    margin: 0;
  }

  @mixin base-step-icon() {
    color: $bg-color-2;
    min-width: 16px;
    min-height: 16px;
    background-color: $icon-color;
  }

  .mat-step-header .mat-step-icon,
  .mat-step-icon .mat-icon {
    @include base-step-icon();
  }

  // .mat-step-icon .mat-icon {
  //   @include base-step-icon();
  // }

  .mat-step-icon-state-done {
    background-color: $status-9 !important;

    .mat-icon {
      background-color: $status-9;
    }
  }

  .mat-step-icon-selected {
    background-color: $font-color-2 !important;
  }

  .mat-step-header .mat-step-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $icon-color;
  }

  .mat-step-label-selected {
    color: $font-color-1 !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
  }

  .mat-horizontal-stepper-header-container {
    border-bottom: 1px solid $border-color-1;
  }

  .mat-horizontal-content-container {
    padding: 0;
  }
}
