@import 'node_modules/bootstrap/scss/forms/form-check';

@mixin choices-extension() {
  .any-choice {
    display: flex;
    align-items: center;

    .form-check-input {
      min-width: 1rem;
      min-height: 1rem;
    }
    .form-check-label {
      padding: $form-check-label-padding;
    }

    &.unchecked {
      .form-check-input {
        &:disabled,
        &.disabled {
          background-color: $gray-400;
          border-color: $gray-500;
          box-shadow: none;
        }

        &:not(:indeterminate):not(:disabled) {
          box-sizing: border-box;
          box-shadow: $form-check-input-box-shadow;
        }

        &:focus-visible {
          box-shadow: $form-check-input-box-shadow, $form-check-input-focus-keyboard-box-shadow;
        }
      }
    }

    &:checked,
    &.checked {
      color: $form-check-input-checked-bg-color;

      .form-check-input {
        &:focus:not(:focus-visible) {
          box-shadow: $form-check-input-focus-checked-box-shadow;
          border: $form-check-input-focus-checked-border;
        }

        &:focus-visible {
          box-shadow: $form-check-input-focus-checked-keyboard-box-shadow;
        }
      }
    }

    &:indeterminate,
    &.indeterminate {
      color: $form-check-input-indeterminate-bg-color;

      .form-check-input {
        &:focus:not(:focus-visible) {
          box-shadow: $form-check-input-focus-indeterminate-box-shadow;
          border: $form-check-input-focus-indeterminate-border;
        }

        &:focus-visible {
          box-shadow: $form-check-input-focus-indeterminate-keyboard-box-shadow;
        }
      }
    }

    &:disabled,
    &.disabled {
      color: $gray-500;
    }
  }
}

@include choices-extension();
