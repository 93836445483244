@mixin cursor-disabled() {
  cursor: not-allowed !important;
}

* {
  &:disabled {
    @include cursor-disabled();
  }
}
.mat-radio-group {
  .mat-radio-disabled,
  .mat-radio-disabled .mat-radio-label {
    @include cursor-disabled();
  }
}

.any-choice:disabled,
.any-choice.disabled {
  @include cursor-disabled();
}
.ng-select-disabled {
  .ng-select-container {
    @include cursor-disabled();
  }
}

.tabbed-nav {
  .tab-disabled {
    @include cursor-disabled();
  }
}

.mat-button.mat-button-disabled,
.mat-icon-button.mat-button-disabled,
.mat-stroked-button.mat-button-disabled,
.mat-flat-button.mat-button-disabled {
  @include cursor-disabled();
}
.disabled-element {
  @include cursor-disabled();
  .container-code .ace_editor .ace_content {
    @include cursor-disabled();
  }
}
