@use '@angular/material' as mat;
@import "core/adpp";

.color-picker {
    height: auto !important;
    border: none !important;
    @include mat.elevation(4);

    .preset-area {
        //padding: 4px 15px;
        padding: 0 0 12px 12px !important;
        height: 140px;
        overflow-y: auto;
        overflow-x: hidden;
        > hr {
            display: none;
        }
        .preset-label {
            display: none;

        }

        .preset-color {
            display: block !important;
            float: left !important;
            margin: 0 !important;
            border: none !important;
            border-radius: 0 !important;
            &:nth-child(14n+3) {
                clear: both;
            }
        }
    }
}
