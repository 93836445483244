@import "mixins/breakpoints";
@import 'variables/variables';

.adpp-snackbar {
  color: $bg-color-2;
  mat-icon {
    color: $bg-color-2;
  }
  @include media-breakpoint-up(sm) {
      width: 50% !important;
  }
}

.custom-snackbar-container {
  background: $bg-color-2;
  position: relative;

  &__icon {
    color: green;
  }

  &__close-icon {
    color: $icon-color;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background: green;
    border-radius: 3px 0px 0px 3px;
  }
}
