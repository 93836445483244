button,
input[type='email'],
input[type='tel'],
input[type='text'],
input[type='password'],
input[type='image'],
input[type='submit'],
input[type='button'],
input[type='search'],
textarea {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
}

$fixed-width: var(--fixed-width);
.fixed-width-form {
  width: $fixed-width;
  min-width: $fixed-width;
  &.important {
    width: $fixed-width !important;
  }
  &.pb-only {
    padding: 0 0 16px 0 !important;
  }
}
.fixed-center-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 24px;
  min-width: $fixed-width;
  .mat-mdc-tab-nav-bar {
    width: $fixed-width !important;
    min-width: $fixed-width !important;
  }
}
.breadcrumb-fixed-width {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;
  .breadcrumb-wrapper {
    width: $fixed-width;
    min-width: $fixed-width;
  }
}
.mat-mdc-tab-nav-panel.center-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
