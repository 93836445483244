@import 'variables/variables';

.markdown-container {
  background-color: $bg-color-2;
  padding: 0 48px 24px;
  max-width: 856px;
  margin: 0 auto;
  white-space: pre-line;
  text-align: justify;
  line-height: 150%;

  ol {
    margin: 0 !important;
    padding-left: 16px !important;
  }
  ul {
    margin: 0 !important;
    padding-left: 32px !important;
    white-space: normal !important;
  }
  ol > li::marker {
    font-weight: bold;
  }

  &.term {
    padding: 0 105px 24px;
  }
  &.privacy {
    padding: 0 105px 24px;
  }
}

@mixin header-term {
  #header-term {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-top: 96px;
    .term-title {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.privacy-wrapper {
  @include header-term;
  .unordered-list-item {
    &.level-0 {
      margin-left: -16px;
      margin-top: -48px;
      margin-bottom: -24px;
      ul li p {
        padding-left: 16px;
      }
    }
    &.level-1 {
      margin-left: 16px;
      margin-top: -48px;
      margin-bottom: -24px;
    }
    &.no-list-style {
      ul {
        list-style-type: none;
      }
      li {
        padding-bottom: 8px;
      }
    }
    &.dash-list-style {
      li {
        padding-left: 8px;
      }
      li::marker {
        content: '-';
        font-weight: bold;
        margin-right: 8px;
        color: $font-color-1;
      }
    }
    &.li-has-header {
      ul li p {
        padding-bottom: 8px;
      }
    }
    &.li-gap-8 {
      ul li p {
        padding-bottom: 8px;
      }
    }
    &.li-gap-16 {
      ul li p {
        padding-bottom: 16px;
      }
    }
  }
}

.terms-wrapper {
  @include header-term;
}
