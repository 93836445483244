@import "variables/variables";

@mixin icon-size($size: 16px) {
  font-size: $size;
  line-height: $size;
  width: $size !important;
  height: $size !important;
  min-width: $size;
  min-height: $size;
}

/** 
* This file contain mixin style for button.
*/

@mixin action-button-style() {
  .action-btn, .action-button {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 16px;
      width: 16px;
      margin-right: 8px;
    }
  }
}
@mixin action-icon-button-style($size: 32px, $icon: 24px) {
  .action-icon-btn {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: $size;
    width: $size;
    height: $size;
    min-height: $size;
    padding: 0;
    .mat-icon {
      min-width: $icon;
      width: $icon;
      height: $icon;
      min-height: $icon;
      color: $font-color-1 !important;
      line-height: $icon;
    }
  }

  .action-icon-text-btn {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;
    width: 100%;
    height: $size;
    min-height: $size;
    padding: 0 16px !important;

    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0;
    }
    .mat-icon {
      min-width: $icon;
      width: $icon;
      height: $icon;
      min-height: $icon;
      line-height: $icon;
      margin-right: 8px;
      color: $font-color-1 !important;
    }
  }
}

