@import 'variables/variables';

@mixin th-border {
  border-bottom: 1px solid $border-color-1 !important;
  border-top: 1px solid $border-color-1 !important;
}

@mixin td-border {
  border-bottom: 1px solid $border-color-2 !important;
}

@mixin mat-table-border {
  th {
    &.mat-mdc-header-cell {
      font-size: 12px !important;
      @include th-border();
    }
  }

  td {
    &.mat-mdc-cell {
      @include td-border();
      font-size: 14px !important;
    }
  }
}

@mixin mat-table-bg-color {
  tr.mat-mdc-header-row {
    background-color: $bg-color-1 !important;

    th.mat-mdc-header-cell {
      color: $font-color-1 !important;
      font-weight: 600 !important;
      font-size: 12px !important;
      line-height: 100% !important;
    }
  }

  td.mat-mdc-cell {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 100% !important;
  }
}

@mixin text-style($size, $weight, $line-height: unset) {
  font-size: $size;
  font-weight: $weight;
  color: $font-color-1;
  line-height: $line-height;
}
