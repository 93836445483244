@use "variables/variables";

body:not(.is-mobile) {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar:hover {
    background-color: #0000001f;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #0000005e;
    border: 2px solid transparent;
    // box-shadow: inset 0 0 0 24px $scrollbar !important;
    border-radius: 24px;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #0000005e;
    border: 4px solid transparent;
    // box-shadow: inset 0 0 0 8px $scrollbar !important;
    border-radius: 24px;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: #0000008a;
    // box-shadow: inset 0 0 0 24px $scrollbar-active !important;
    border-radius: 24px;
  }
}

body {
  &.mat-dark-theme {
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar:hover {
      background-color: #394047;// -> #394047
    }

    ::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
      // box-shadow: inset 0 0 0 24px $scrollbar !important;
      border-radius: 24px;
      // background: #71767B;
      background: #71767B; // #71767B
    }

    ::-webkit-scrollbar-thumb:horizontal {
      background-color: #71767B;
      border: 4px solid transparent;
      // box-shadow: inset 0 0 0 8px $scrollbar !important;
      border-radius: 24px;
    }

    ::-webkit-scrollbar-thumb:active {
      background-color: #989B9F; // #989B9F
      // box-shadow: inset 0 0 0 24px $scrollbar-active !important;
      border-radius: 24px;
    }
  }

}