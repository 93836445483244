@use '@angular/material' as mat;
@import 'variables';
@import 'palette';

@import '@angular/cdk/overlay-prebuilt.css';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/helpers';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/utilities/api';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/close';

@import 'any-ui/variables';
@import 'any-ui/scss/button';
@import 'any-ui/scss/choice';
@import 'any-ui/scss/switch';
@import 'any-ui/scss/breadcrumb';
@import 'any-ui/scss/tooltip';


$prefix: bs- !default;

$mat-white: (
  D 500: white,
  contrast: (500: rgba(black, 0.87),
  ),
);

$mat-black: (
  500: black,
  contrast: (500: white,
  ),
);

// Define theme vars
$primary: mat.m2-define-palette($am-primary-palette); // TODO: check all material component with `color="primary"`
$accent: mat.m2-define-palette($am-blue-palette);
$warn: mat.m2-define-palette(mat.$m2-red-palette);
$custom-typography: mat.m2-define-typography-config($font-family: 'Inter, sans-serif',
    $headline-1: mat.m2-define-typography-level(32px, 48px, 700),
    $subtitle-1: mat.m2-define-typography-level(13px, 13px, 400),
    $subtitle-2: mat.m2-define-typography-level(13px, 13px, 600),
    $body-1: mat.m2-define-typography-level(13px, 13px, 400),
    $body-2: mat.m2-define-typography-level(13px, 13px, 400),
    $button: mat.m2-define-typography-level(13px, 13px, 400),
  );

$light-theme: mat.m2-define-light-theme((color: (primary: $primary,
        accent: $accent,
        warn: $warn,
      ),
      typography: $custom-typography,
    ));

$dark-theme: mat.m2-define-dark-theme((color: (primary: $primary,
        accent: $accent,
        warn: $warn,
      ),
      typography: $custom-typography,
    ));