@use 'sass:color';
@use "variables/variables";

.editor {
  border: 1px solid $border-color-1;
  box-shadow: inset 0px 1px 2px rgba(#3892E5, 0.2);
  border-radius: 3px;
  height: auto;
  padding: 0 !important;


  .NgxEditor__MenuBar {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background: $bg-color-1;
    border-bottom: 1px solid $border-color-1;
  }

  .NgxEditor {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: none !important;
    @at-root &__Content {
      overflow: auto;
      max-height: 218px;
      min-height: 155px;
      background-color: $bg-color-2;
      color: $font-color-1;
    }
  }

  .CodeMirror {
    border: 1px solid #eee;
    height: auto;
    margin-bottom: 0.7rem;

    pre {
      white-space: pre !important;
    }
  }
  .NgxEditor__Content.NgxEditor__Content--Disabled {
    background-color: $bg-disabled;
    color: $font-color-disabled;
    pointer-events: auto !important;
  }
}
