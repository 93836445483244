@use 'sass:color';
@import 'variables/variables';
@import 'mixins/typo';
/**
* This file contain custom material component style.
* All custom of material component should be here.
*/

// Fix: "Icon button ripple radius is not correct on Edge & Safari"
.mat-icon-button {
  .mat-button-ripple {
    border-radius: 50%;
  }
}

// Fix: "Sidenav opening with animations for the first time"
body {
  > mat-sidenav-container {
    height: 100%;
  }
}

// Fix: "Inconsistent font sizes across elements"
.mat-input-wrapper {
  font-size: 16px;
}

mat-cell:first-child,
mat-footer-cell:first-child,
mat-header-cell:first-child {
  padding-left: 0 !important;
}

.mat-pseudo-checkbox-checked:after {
  width: 14px;
  height: 7px;
}

// MAT SIDENAV
mat-sidenav-container {
  mat-sidenav {
    &[mat-is-locked-open].mat-stop-transition {
      transition: none !important;
      transform: translate3d(0, 0, 0) !important;
      opacity: 0;

      ~ .mat-sidenav-content,
      ~ .mat-drawer-content {
        transition: none !important;
      }
    }

    &.mat-sidenav-opened {
      &.mat-drawer-side {
        ~ .mat-sidenav-content,
        ~ .mat-drawer-content {
          transition: none !important;
          transform: translate3d(0, 0, 0) !important;
        }
      }
    }
  }
}

// MAT MENU
.mat-menu-content {
  background-color: $bg-color-2;
  color: $font-color-1;
}

.mat-menu-item {
  background-color: $bg-color-2;
  color: $font-color-1;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.mat-menu-item:hover:not([disabled]) {
  background-color: $bg-color-1;
}

.mat-menu-trigger {
  color: $font-color-1;

  .mat-icon {
    color: $font-color-2 !important;
  }
}

// MAT RADIO BUTTON - will remove after apply any radio
.mat-radio-button {
  font-size: 16px;

  .mat-radio-label-content {
    color: $font-color-1 !important;
  }

  &.mat-radio-checked {
    .mat-radio-label-content {
      color: $font-color-1 !important;
    }
  }
}

// MAT CHIP LIST
.mat-chip-list,
.custom-mat-chip {
  &:focus-within {
    border-bottom: 1px solid $am-primary !important;
    .mdc-evolution-chip-set__chips {
      border-color: $am-primary !important;
    }
  }

  .mat-mdc-chip-input {
    height: 24px;
    margin: 0 !important;
    background-color: $bg-color-2;
    color: $font-color-1;
    caret-color: $font-color-1;
  }
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--selected) .mdc-evolution-chip__action--primary:before {
    border-width: 0px;
  }
  .mdc-evolution-chip-set__chips {
    min-height: 36px;
    border-bottom: 1px solid $border-color-1;
    margin: 0;
    background-color: $bg-color-2;
    padding: 0 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 0px !important;
    padding-left: 4px;
  }
  .mat-mdc-chip-row {
    background-color: $bg-color-1 !important;
    border-radius: 23px;
    height: 24px;
    padding: 3px 8px !important;
    line-height: 24px;
    min-height: 24px;
    color: $font-color-1;
    font-size: 13px;
    margin: 0 !important;
    .mat-mdc-chip-action-label {
      font-weight: 500;
    }
    mat-icon {
      line-height: 24px;
      text-align: right;
      font-size: 16px !important;
    }
  }
}
// CUSTOM mat button toggle
.mat-button-toggle-group {
  border: none !important;
  .mat-button-toggle:first-child {
    border-radius: 4px 0 0 4px;
    border: 1px solid $border-color-1;
    border-right: none;
  }
  .mat-button-toggle:last-child {
    border-radius: 0 4px 4px 0;
    border: 1px solid $border-color-1;
    border-right: 1px solid $border-color-1;
    border-left: none !important;
    &.mat-button-toggle-checked {
      border-left: 1px solid $am-primary !important;
    }
  }
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  background-color: rgba(#3892e5, 0.1) !important;
  border: 1px solid $am-primary !important;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  border: 1px solid $am-primary !important;
}

.mat-button-toggle-group-appearance-standard
  .mat-button-toggle.mat-button-toggle-appearance-standard.mat-button-toggle-checked
  + .mat-button-toggle {
  border-left: none !important;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: 1px solid $border-color-1 !important;
  border-top: 1px solid $border-color-1;
  border-bottom: 1px solid $border-color-1;
}

// MAT TREE
.mat-tree {
  background: $bg-color-2 !important;

  .node-name-item {
    color: $font-color-1;
  }
}

// MAT TOOLTIP
.mat-tooltip-panel {
  .mat-tooltip-show {
    background-color: $bg-tooltip;
    color: $font-color-tooltip;
    box-shadow: $box-shadow;
  }
}

// MAT paging
mat-paginator.mat-mdc-paginator,
.mat-mdc-paginator {
  background-color: $bg-color-1;
  margin-top: 0;
  .mat-mdc-paginator-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0;
  }
  .mat-mdc-form-field.mat-primary
    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__leading,
  .mat-mdc-form-field.mat-primary
    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__notch,
  .mat-mdc-form-field.mat-primary
    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__trailing {
    border-color: $font-color-1;
    border-width: 1px;
  }
  .mat-mdc-select-trigger {
    padding-left: 6px;
    padding-right: 42px;
    height: 100%;
    text-align: center;
  }
  .mat-mdc-select-arrow-wrapper {
    position: absolute;
    right: 12px;
  }
  .mat-mdc-paginator-range-label {
    @include text-dark(13px, 400);
    margin: 0 24px;
  }
  .mat-mdc-paginator-page-size-label {
    @include text-dark-grey(13px, 400);
  }
  .mat-mdc-paginator-page-size-select {
    width: 75px;
    .mat-mdc-select-value {
      @include text-dark(14px, 400);
    }
  }
  .mat-mdc-text-field-wrapper {
    padding-left: 4px !important;
    padding-right: 0px !important;
    .mat-mdc-form-field-infix {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
      min-height: 32px !important;
    }
  }
  .mat-mdc-paginator-range-actions {
    @mixin base-paging-button($width: 32px) {
      width: $width;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px !important;
      @include text-dark-grey(12px, 400);
      &:hover,
      &:focus {
        .mat-mdc-paginator-icon {
          fill: $font-color-1 !important;
        }
        color: $font-color-1 !important;
        background-color: $bg-color-2 !important;
        border-color: $border-color-4 !important;
      }

      &:active {
        .mat-mdc-paginator-icon {
          fill: $font-color-1 !important;
        }
        color: $font-color-1;
        background: $bg-color-1 !important;
        border-color: $border-color-4 !important;
      }

      &.disabled,
      &:disabled {
        .mat-mdc-paginator-icon {
          fill:  $font-btn-disabled !important;
        }
        color: $font-btn-disabled !important;
        background-color: $bg-btn-disabled !important;
        border-color: $border-color-1 !important;
        cursor: not-allowed !important;
      }
    }
    @mixin default-border-bg() {
      border-radius: 3px !important;
      border: 1px solid $border-color-1 !important;
      background: $bg-color-2 !important;
    }
    .mat-mdc-paginator-icon {
      width: 16px;
      height: 16px;
    }
    .mat-mdc-paginator-navigation-previous, .mat-mdc-paginator-navigation-first {
      @include base-paging-button(88px);
      @include default-border-bg();
      margin-right: 8px !important;
    }
    .mat-mdc-paginator-navigation-next, .mat-mdc-paginator-navigation-last {
      @include base-paging-button(88px);
      @include default-border-bg();
      margin-left: 8px !important;
    }
    .btn_custom-paging-container {
      display: flex;
      gap: 8px;
      .btn-paging-number {
        @include base-paging-button(32px);
        @include default-border-bg();
        &.is-selected {
          background-color: $font-color-1 !important;
          color: $bg-color-2 !important;
          user-select: none;
        }
      }
      .btn-paging-dots {
        @include base-paging-button(32px);
        background-color: transparent !important;
      }
    }
  }
}
.mat-dark-theme {
  mat-paginator.mat-mdc-paginator,
  .mat-mdc-paginator {
    .mat-mdc-paginator-range-actions {
      .btn-paging-number,
      .mat-mdc-paginator-navigation-previous,
      .mat-mdc-paginator-navigation-next {
        &:hover,
        &:focus {
          background-color: #3d464f !important;
        }

        &:active {
          background-color: #525a62 !important;
        }

        &.disabled,
        &:disabled {
          .mat-mdc-paginator-icon {
            fill: #56616b !important;
          }
          background-color: #27313b !important;
          color: #56616b !important;
        }
        &.is-selected {
          background-color: $font-color-1 !important;
          color: $bg-color-2 !important;
          user-select: none;
        }
      }
      .btn-paging-dots {
        background-color: transparent !important;
        &.disabled,
        &:disabled {
          background-color: transparent !important;
        }
      }
    }
  }
}
.async-error-loading {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $font-color-1;
  }
  &.right-side {
    position: absolute;
    right: -20px;
    top: 36px;
  }
}

.mat-mdc-tab-header {
  border-bottom: 1px solid $bg-color-1 !important;
}
.mat-mdc-icon-button.mat-mdc-button-base {
  width: 32px;
  height: 32px;
}

.mat-mdc-button:not(:disabled) {
  color: inherit !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
  min-width: calc(100% + 48px);
  transform: scaleY(1);
  padding: 0 !important;
  border-radius: 4px !important;
  background-color: $bg-color-2 !important;
}
.mat-mdc-option.mdc-list-item:not(.mdc-list-item--disabled) {
  margin: 8px;
}

.mat-mdc-option {
  min-height: 39px !important;
  color: $font-color-1 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  .mat-pseudo-checkbox {
    display: none;
  }
}
.mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: #1e262e !important;
  --mdc-plain-tooltip-container-color: #1e262e !important;
  color: white !important;
}
.mat-dark-theme {
  .mat-dialog-container {
    background-color: #27313b;
  }
  .mat-paginator {
    .mat-paginator-page-size-label {
      color: $font-color-1 !important;
    }
  }
  .mat-button-toggle-appearance-standard {
    background-color: transparent;
  }
  .mat-mdc-paginator {
    background-color: $bg-color-1 !important;
  }
  .mat-mdc-table,
  .mat-stepper-horizontal,
  .mat-stepper-vertical,
  .mat-expansion-panel {
    background-color: $bg-color-2 !important;
  }
  .mat-drawer {
    background-color: $bg-color-2 !important;
  }
  .mat-drawer-container {
    background-color: #1e262e;
  }
  .mdc-list-item--selected .mdc-list-item__primary-text {
    color: $font-color-1 !important;
  }
  .mat-mdc-dialog-container {
    background-color: $bg-color-2 !important;
    --mdc-dialog-container-color: $bg-color-2 !important;
    color: $font-color-1;
  }
}
.mat-mdc-tab-group {
  .mat-mdc-tab-header {
    .mat-mdc-tab-labels {
      .mdc-tab__content {
        font-size: 14px;
      }
      .mdc-tab--active {
        font-weight: 600 !important;
      }
    }
  }
}
.mat-mdc-tab-link {
  &.mdc-tab--active {
    .mdc-tab__text-label {
      font-weight: 600 !important;
    }
    &:hover {
      .mdc-tab-indicator__content--underline {
        border-color: $am-primary !important;
      }
    }
    .mdc-tab-indicator__content--underline {
      opacity: 1;
    }
  }
  .mdc-tab-indicator__content--underline {
    border-color: $am-primary !important;
  }
}
.mat-mdc-menu-content {
  background-color: $bg-color-2 !important;
  color: $font-color-1 !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked.cdk-focused:not(:hover) .mdc-radio__background::before {
  opacity: 0;
}
.mdc-data-table__row:last-child .mat-mdc-cell {
  border-bottom: 1px solid $border-color-1 !important;
}
