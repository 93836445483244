@import '../mixins/custom-btn';
@import 'variables/variables';

p,
body {
  font-family: 'Inter', sans-serif;
}

* {
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

a {
  color: $am-primary;
}

.panel-well {
  background: $bg-color-2;
  box-shadow: $box-shadow;
  border-radius: 3px;
  margin-bottom: 24px;

  &.tw-mb-0 {
    margin-bottom: 0;
  }

  font-size: 13px;

  .panel-well-heading {
    position: relative;
    border-bottom: $border-color-3 1px solid;
    padding: 16px 24px;

    font-size: 14px;

    label.panel-well-heading-title {
      display: inline-block;
      max-width: calc(100% - 32px);
      word-break: break-word;
    }

    span {
      color: #787878;
      font-size: 13px;
      font-weight: 100;

      strong {
        font-weight: normal !important;
      }
    }

    .button-controls {
      position: absolute;
      top: 18px;
      right: 0;
      display: inline-flex;
      padding: 0;

      > a {
        display: inline-block;
        margin-right: 6px;
      }

      mat-icon {
        font-size: 18px !important;
        height: 18px !important;
        width: 18px !important;
      }
    }
  }

  .panel-well-body {
    padding: 16px;
  }

  .panel-well-footer {
    border-top: $border-color-3 1px solid;
    padding: 24px;
    position: relative;
    min-height: 86px;

    .is-right {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
}

.panel-well-grey {
  background-color: $bg-color-1;
}

.control-label {
  font-weight: 400;
  font-size: 13px;
  margin-top: 8px;
}

.form-group.row .control-label {
  margin-top: 18px;
}

.add-board-container {
  height: 100%;
  display: flex;
  padding: 16px $spacing-24;
  flex-direction: column;

  .page-heading {
    background-color: $bg-color-2;
    border-bottom: 1px solid $border-color-3;
    padding: 8px 24px;
    margin-bottom: 0;
    height: 46px;
    line-height: 28px;

    .is-separate {
      padding: 0 6px;
    }

    > button,
    > a {
      margin-left: 14px;
    }

    .is-back {
      display: inline-block;
      margin-right: 8px;
      margin-left: 0 !important;

      position: relative;
      bottom: -2px;

      i.fa {
        font-size: 18px;
        color: $am-primary !important;
      }
    }
  }

  .add-board-container-main {
    .table-responsive {
      position: relative;
    }
  }
}

.is-error {
  font-size: 12px;
  color: $status-0;
}

.form-group {
  margin-bottom: 18px;
  position: relative;

  .icon {
    position: absolute;
    font-size: 14px;
    min-width: 14px;
    min-height: 14px;
    height: 14px;
  }

  .icon-text {
    position: absolute;
    top: 36px;
    right: 16px;
    font-size: 12px;
    color: $icon-color;
  }

  any-checkbox {
    label > span {
      color: $font-color-1;
    }
  }

  label {
    font-weight: 400;
    font-size: 14px;
    color: $font-color-1;
    display: flex;
    align-items: center;
    gap: 4px;

    > span {
      color: $status-0;
    }

    &.is-error {
      font-size: 12px;
      color: $status-0 !important;
    }

    &.mb-8 {
      margin-bottom: 8px;
    }
  }

  .is-error {
    position: absolute;
    color: $status-0 !important;
    bottom: -16px;

    label {
      font-size: 12px;
      color: $status-0 !important;
      display: block;
    }

    &-label {
      color: $status-0 !important;
    }

    &.bottom-0 {
      bottom: 0 !important;
    }

    &.bottom-unset {
      bottom: unset !important;
    }

    &.long-error {
      position: relative;
      bottom: 0;

      p {
        margin: 0 !important;
      }
    }

    &.one-line-error {
      label {
        white-space: nowrap;
      }
    }

    &.error-msg-overflow {
      white-space: nowrap;
      overflow: unset;
    }
  }

  any-input {
    .input-group {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }

  .form-control {
    margin-top: 8px;
    width: 100%;
    padding: 8px 12px !important;
    height: 36px;
    font-size: 11px;
    border: solid 1px $border-color-1;
    border-radius: 3px;
    background-color: $bg-color-2;
    color: $font-color-1;
    caret-color: $font-color-1;
  }

  .form-control[disabled] {
    background: $bg-disabled;
    color: $font-color-1;
  }

  .form-control[readonly] {
    background: $bg-disabled;
    color: $font-color-1;
  }

  .form-control.is-small {
    width: 120px;
  }

  span.is-right {
    display: inline-block;
    margin-right: 8px;
  }

  .mat-form-field-infix {
    border: none !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0 !important;
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    position: absolute;
    bottom: 6px;
    right: 8px;
    z-index: 1;
  }

  select.form-control {
    background: $bg-color-2;
    font-size: 12px !important;

    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;

    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;

    //-webkit-appearance: none;
  }

  @mixin error-form-field() {
    border: 1px solid $status-0 !important;
    background: rgba(255, 95, 95, 0.05) !important;
  }

  .form-field-error {
    input:not(.form-check-input),
    input[disabled],
    input[readonly],
    .ng-select .ng-select-container {
      @include error-form-field();
    }

    .ng-select .search-box,
    .ng-select .select-search-box {
      input {
        border: none !important;
        background-color: inherit !important;
      }
    }

    .ng-select .ng-dropdown-panel-items .ng-option any-checkbox .any-choice input:not(.form-check-input) {
      background-color: #ffffff !important;
      border: solid 1px #c5d0da !important;
    }
  }

  input.form-field-error,
  textarea.form-field-error {
    @include error-form-field();
  }

  // TODO: wait for figma
  // .mat-chip-list.form-field-error {
  //   .mat-chip-list-wrapper {
  //     border: 1px solid $status-0 !important;
  //     border-radius: 3px;
  //   }
  //   input {
  //     border: none !important;
  //     background-color: inherit !important;
  //   }
  // }
  .editor.form-field-error {
    border: 1px solid $status-0 !important;
  }
}

.form-group.is-left {
  .form-control {
    padding-left: 40px !important;
  }

  .icon {
    position: absolute;
    top: 38px;
    left: 12px;
    right: auto;

    font-size: 16px;
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;

    &.no-label {
      top: 19px;
    }
  }
}

.form-group.is-check-boxes {
  padding-top: 8px;

  > label {
    display: inline-block;
    margin-right: 4px;
  }
}

.is-cursor {
  cursor: pointer;
}

.circle {
  display: inline-block;
  width: 8px;
  height: 8px;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  min-height: 0;
  min-width: 0;

  position: relative;
  top: 0;
  margin-right: 4px;
}

.circle-PRIVATE,
.circle-1 {
  // APPROVAL_REQUIRED
  background: $status-10;
}

.circle-PUBLIC,
.circle-2 {
  // ACTIVE
  background: $am-primary;
}

.circle-active {
  background-color: $status-9;
}

.circle-inactive {
  background-color: $status-12;
}

.text-PUBLIC {
  color: $am-primary !important;
  font-size: 13px;
}

.text-PRIVATE {
  color: $status-10 !important;
  font-size: 13px;
}

.text-UNFIXED {
  color: $status-12 !important;
  font-size: 13px;

  span {
    color: $status-12 !important;
    font-size: 13px;
  }
}

.text-DARK {
  span {
    color: $font-color-1;
    font-size: 13px;
  }
}

.menu-item {
  span.mdc-list-item__primary-text {
    font-weight: 600 !important;
    height: 40px;
    line-height: 40px !important;
  }
}

.mat-mdc-menu-item {
  min-height: 40px !important;
}

button.mat-mdc-menu-trigger {
  display: flex;
  align-items: center;
}

a {
  cursor: pointer;
}

.add-card-dialog {
  mat-dialog-container {
    padding: 0;
  }
}

.base-margin {
  margin: 24px;
}

.padding-16 {
  padding: 16px;
}

.form-card {
  border-radius: 3px;
  box-shadow: $box-shadow;
  background-color: $bg-color-2;
}

.select-site {
  margin: 16rem 0;
  font-size: 20px;
  line-height: 24px;
  color: $icon-color;
}

.line-top-table {
  padding-top: 16px;
  border-top: solid 1px $border-color-2;

  .total-items {
    font-size: 18px;
    color: $font-color-1;
  }
}

.promote-panel-overlay {
  mat-dialog-container {
    padding: 0 !important;
  }
}

.custom-icon-button-24 {
  line-height: 24px !important;
  width: 24px !important;
  height: 24px !important;

  &.mat-button-disabled {
    color: $font-btn-disabled !important;
  }
}

.clickable {
  cursor: pointer;
}

.open-ads-txt-icon {
  cursor: pointer;

  .mat-icon {
    font-size: 16px;
    line-height: 16px;
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
    color: $am-primary !important;
  }

  &.error-ads-txt {
    .mat-icon {
      color: $status-0 !important;
    }
  }

  &.disabled {
    pointer-events: none;

    .mat-icon {
      color: $font-color-disabled !important;
    }
  }
}

.tooltip-formula {
  font-size: 12px;
  line-height: 150%;
  white-space: pre-line;
  color: $font-color-1;

  .mdc-tooltip__surface {
    text-align: left;
  }
}

.dimension-tooltip {
  word-break: break-all !important;
  white-space: normal !important;
  max-width: initial !important;
}

.instruction-section {
  .instruction-content-header {
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: $font-color-1;
    margin-bottom: 4px;
    margin-top: 16px;
  }

  .ol-padding-8 {
    padding-left: 8px;
  }

  ul,
  ul.bulk-update-content {
    li {
      list-style-type: disc;
    }
  }

  ol,
  ol.bulk-update-content {
    li {
      list-style-type: decimal;
    }

    ul > li {
      list-style-type: disc;
    }
  }

  ul.bulk-update-content,
  ol.bulk-update-content {
    margin: 16px 0 0 0 !important;
    padding: 0 16px !important;

    li {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.sub-content {
      margin-top: 0 !important;
      padding-left: 40px !important;

      li:last-child {
        margin-bottom: 8px;
      }
    }

    &.style-numberic {
      list-style: none !important;
      counter-reset: item;
      padding-left: 0 !important;

      li.count {
        counter-increment: item;
        margin-bottom: 5px;
        list-style-type: none !important;

        &:before {
          margin-right: 8px;
          content: counter(item);
          font-variant-numeric: tabular-nums;
          font-size: 80%;
          background-color: $font-color-1;
          color: $bg-color-2;
          font-weight: bold;
          border-radius: 100%;
          width: 18px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          height: 18px;
        }
      }
    }
  }
}

.menu-icons-overlay {
  overflow: hidden !important;
  @include action-icon-button-style(32px, 16px);

  .mat-menu-content:not(:empty) {
    padding: 4px;
    border-radius: 3px;
  }
}

.base-margin-content {
  // new design
  margin: 0 24px 24px;
}

.w-full {
  width: 100%;
}

.back-icon {
  color: $am-primary !important;
  cursor: pointer !important;
}

.toolbar-icon {
  width: 24px;
  height: 24px;
}

@mixin add-border($radius: 3px) {
  border: 1px solid $border-color-1;
  border-radius: $radius;
}

.report-mat-select {
  @include add-border(3px);

  .mat-mdc-select-trigger {
    height: 30px;
    line-height: 30px;
    padding: 0 6px 0 12px;
    font-size: 12px;
  }
}

.mat-mdc-select-arrow-wrapper {
  margin-left: 3px;
}

$input-placeholder: $icon-color;

.ng-placeholder {
  color: $input-placeholder !important;
}

input::placeholder {
  /* for Most modern browsers */
  color: $input-placeholder !important;
}

//
.is-mmp-report {
  .any-choice.form-check.disabled {
    .form-check-label {
      color: $font-color-1 !important;
    }
  }
}

.container-action-bar-sticky {
  max-height: calc(100vh - 106px);
  overflow-x: scroll;
}

.info-icon {
  font-size: 16px;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
}
.morden-dialog-box {
  .question {
    .thread-name {
      font-weight: 500;
      background: #e6edf2;
      color: #27313b !important;
    }
  }
}