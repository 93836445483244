@import 'ngx-toastr/toastr';
@import 'variables/variables';

@mixin base-toastr {
  background: $bg-color-2;
  padding: 16px;
  border-radius: 3px;
  box-shadow: $box-shadow;
  box-sizing: border-box;
  display: block;
  max-width: 33vw;
  min-width: 344px;
  min-height: 48px;
  transform-origin: center;
}

@mixin base-img-icon {
  .icon-section {
    img {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
    }
  }
}

#toast-container > .tr-custom-toastr-container {
  background-image: none !important;
}

#toast-container > .toast-success {
  .custom-toast-container {
    @include base-toastr();
    @include base-img-icon();
    border-left: 4px solid $status-9;

    &__icon {
      color: $status-9 !important;
    }
  }

  .toast-progress {
    background: $status-9 !important;
  }
}

#toast-container > .toast-error {
  .custom-toast-container {
    @include base-toastr();
    @include base-img-icon();
    border-left: 4px solid $status-0;

    &__icon {
      color: $status-0 !important;
    }
  }

  .toast-progress {
    background: $status-0 !important;
  }
}

#toast-container > .toast-info {
  .custom-toast-container {
    @include base-toastr();
    @include base-img-icon();
    border-left: 4px solid $am-primary !important;

    &__icon {
      color: $am-primary !important;
    }
  }

  .toast-progress {
    background: $am-primary !important;
  }
}

#toast-container > .toast-warning {
  .custom-toast-container {
    @include base-toastr();
    @include base-img-icon();
    border-left: 4px solid $status-11;

    &__icon {
      color: $status-11 !important;
    }
  }

  .toast-progress {
    background: $status-11 !important;
  }
}

.tr-custom-toastr-container {
  margin: auto;
}
