@import 'variables/variables';

.custom-nav-header {
  margin: 16px 24px !important;

  .toolbar-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: $font-color-1;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .back-button-box {
    height: 32px;
    width: 32px;
    background: $bg-color-2 !important;
    border: 1px solid $border-color-1 !important;
    box-sizing: border-box;
    border-radius: 3px;

    .mat-mdc-icon.back-icon, .mat-icon.back-icon {
      width: 32px !important;
      height: 32px !important;
      line-height: 12px !important;
      font-size: 12px !important;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: $font-color-2;
      padding-right: 4px;
      padding-bottom: 2px;
    }
  }
}

.tabbed-nav {
  background: $bg-color-2 !important;
  border-bottom: 1px solid $border-color-1 !important;
  a.mat-mdc-tab-link {
    color: $font-color-1;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    flex-grow: unset !important;
    padding: 0 8px;
    min-width: 148px;
    opacity: 1;
    .mdc-tab__content {
      padding: 0 24px;
    }
    &.mdc-tab--active {
      font-weight: 600 !important;
    }
    .mdc-tab-indicator__content--underline {
      --mdc-tab-indicator-active-indicator-color: #3892E5 !important;
      border-top-width: 4px !important;
    }
    .mdc-tab__text-label {
      color: $font-color-1 !important;
    }
  }
}

.form-details {
  .form-card {
    background: $bg-color-2;
    box-shadow: $box-shadow;
    border-radius: 3px;
  }
}
