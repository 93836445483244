@use 'variables/variables';
@import '../mixins/table_border';

.table {
  border-collapse: collapse !important;
}

.table td,
.table th {
  background-color: $bg-color-2;
  font-size: 13px;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid $border-color-3 !important;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 8px 16px;
  line-height: 1;
  vertical-align: top;
  border-top: 1px solid $border-color-3;
}

// table header height always 40px (not include border)
.table > thead > tr > th,
table .mat-mdc-header-row {
  vertical-align: bottom;
  background: $bg-color-1;
  border-bottom: 2px solid $border-color-3;
  padding: 8px 16px !important;
  height: 40px !important;

  font-size: 12px !important;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.28px;
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 2px solid $border-color-3;
}

.table .table {
  background-color: $bg-color-2;
}

// only use for date picker
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}

// table bordered style is only used for report table data.
.table-bordered {
  border: 1px solid $border-color-3;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid $border-color-3;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-of-type(odd) td {
  background-color: $bg-color-1 !important;
}

table col[class*='col-'] {
  position: static;
  display: table-column;
  float: none;
}

table td[class*='col-'],
table th[class*='col-'] {
  position: static;
  display: table-cell;
  float: none;
}

.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

app-query-result .report-table-data-block {
  margin-top: 24px;
}

.report-table-data-block {
  display: flex;
  padding: 0;
  flex-direction: row;
  overflow: scroll;

  .table-striped > tbody > tr:nth-of-type(odd) td {
    background-color: $bg-color-2 !important;
  }

  .table-bordered td {
    border: 1px solid $border-color-2 !important;
  }

  .table-scroll-left,
  .table-scroll-right {
    transform: rotateX(180deg);
    overflow: unset;
    float: none;

    .table {
      tr {
        &.hover {
          td {
            background-color: $bg-color-1 !important;
          }
        }
      }
    }
  }

  .table-scroll-right {
    min-width: 30% !important;

    .table {
      // border-right: solid 1px $border-color-1 !important;
      thead {
        tr th {
          border-right: none !important;
          border-left: none !important;
        }
      }
      td {
        padding: 6px 16px !important;
        div {
          text-align: right;
          font-variant-numeric: tabular-nums;
        }
      }

      th {
        div {
          text-align: right;
        }
      }
    }
  }

  .table-scroll-left {
    border-right: 1px solid $border-color-1;
    box-shadow: 4px 0 16px -3px $border-color-1;
    position: relative;
    z-index: 1;
    .table th,
    .table td {
      padding: 6px 16px !important;

      div {
        text-align: left;
      }
    }
  }

  .table {
    max-width: none;
    margin-bottom: 0;
    table-layout: fixed;
    transform: rotateX(180deg);

    border: none !important;

    td {
      padding: 8px 16px 8px 24px !important;
      div {
        text-align: right;
      }
    }
  }

  .table th {
    white-space: nowrap;
    vertical-align: middle;
  }

  .table td {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    @include text-style(14px, 400, 16px);
    vertical-align: middle !important;
  }

  .table th,
  .table td {
    white-space: nowrap;
    padding: 6px 16px !important;
    margin: 0 !important;
  }

  .table thead {
    overflow-y: auto; /* Trigger vertical scroll    */
    overflow-x: hidden; /* Hide the horizontal scroll */
    display: block;

    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);

    background-color: $bg-color-1;
    border-bottom: none;

    th {
      background-color: $bg-color-1 !important;
      border-top: 0px !important;
      border-right: 1px solid $border-color-1 !important;
      border-bottom: 1px solid $border-color-1 !important;
      border-left: none !important;
      @include text-style(14px, 600, 16px);
      color: $font-color-1;
    }
  }

  .table tbody {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;

    tr td:first-child {
      border-left: none !important;
    }
  }

  .table tbody::-webkit-scrollbar {
    width: 0 !important;
  }

  .table tbody {
    -ms-overflow-style: none;
  }

  .table tbody {
    overflow: -moz-scrollbars-none;
  }

  .is-summary td {
    background-color: $status-5 !important;
    color: $font-color-1 !important;
    @include text-style(14px, 600, 16px);
    height: 40px;
  }
}
.mat-dark-theme {
  .report-table-data-block {
    .table-scroll-left {
      box-shadow: 10px 0 16px -3px $bg-color-1;
    }
    .is-summary td {
      background-color: $bg-color-1 !important;
    }
  }
}

// general table style
.table-list-container {
  position: relative;
  min-height: 150px;
  overflow: auto;
  table {
    width: 100%;
    box-shadow: none;
    border: none;
    .mat-mdc-header-row {
      th {
        font-weight: 600;
        color: $font-color-1;
        border-top: solid 1px $border-color-1;
        word-wrap: initial;
        display: table-cell;
        line-break: unset;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;

        &.number-format {
          text-align: right;
        }
      }
    }
    .mat-mdc-row {
      td {
        word-wrap: initial;
        display: table-cell;
        line-break: unset;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;
        max-width: 300px;
        color: $font-color-1;
      }
    }
  }
  &.with-sort-col {
    .table > thead > tr > th,
    table .mat-mdc-header-row {
      padding: 4px 16px !important;
    }
  }

  &.scroll-table, .scroll-table {
    overflow: auto;
  }

  .table tbody tr td {
    font-size: 14px !important;
  }
  .table td,
  .table th {
    font-size: 12px !important;
    vertical-align: middle;

    > div:not(.bypass-minwidth) {
      min-width: 105px;
    }

    > div.is-sm {
      width: 44px;
      min-width: 0;

      word-break: break-all;
    }

    > div.is-ssm {
      width: 60px;
      min-width: 0;
    }

    > div.is-lg {
      min-width: 140px;
    }

    > div.is-llg {
      min-width: 180px;
    }

    > div.is-lllg {
      min-width: 180px;
    }
  }

  th.is-sm {
    width: 40px;
    min-width: 0;
  }

  .line {
    border-bottom: $border-color-3 1px solid;
    height: 1px;
    margin: 16px 0 24px;
  }

  .table th {
    background-color: $bg-color-1 !important;
    border-bottom: none !important;
    .mat-icon-button {
      padding: 0;
      min-width: 0;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      line-height: 1;
      border-radius: 50%;
      .mat-button-wrapper {
        color: #3a84c5;
        font-weight: normal !important;
        font-size: 12px;
      }

      mat-icon {
        color: #3a84c5;
        position: relative;
        top: -1px;
        font-size: 18px;
      }
    }

    mat-menu > button > span {
      font-size: 12px !important;
    }
  }
}

// Shared table style
// NOTE: if table is all text -> use table-sm-size
// if table has element with 24px height (e.g: checkbox) -> use table-medium-size
// if table has element with 32px height (e.g: selection, input) -> use table-lg-size
// if table has element with 40px height (e.g: image, complex row content) -> use table-xl-size
@mixin table-row-height($height, $padding: 8px 16px) {
  tr.mat-mdc-row,
  tbody tr td {
    padding: $padding;
    height: $height !important;
  }
}

@mixin hover-effect($color: $bg-color-1) {
  tbody tr:hover,
  tbody tr.row-selected {
    background-color: $color;
    td {
      background-color: $color;
    }
  }
}

.table-xs-size {
  @include table-row-height(32px, 6px 16px);
  @include hover-effect($bg-color-1);
}
.table-sm-size {
  @include table-row-height(40px, 6px 16px);
  @include hover-effect($bg-color-1);
}
.table-lg-size {
  @include table-row-height(48px);
  @include hover-effect($bg-color-1);
}
.table-xl-size {
  @include table-row-height(56px);
  @include hover-effect($bg-color-1);
}
// default table styles
table {
  @include mat-table-border();
  .h-24 {
    height: 24px !important;
  }
  any-checkbox {
    .form-check {
      height: 24px !important;
    }
  }
  .td-empty-row {
    font-size: 18px;
    line-height: 140%;
    color: $font-color-1;
  }
  // SORT
  .sort-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }
  .sort-area {
    height: 100%;
    margin: auto 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    mat-icon.sort-icon, .sort-icon {
      font-size: 16px !important;
      line-height: 16px;
      width: 16px !important;
      min-width: 16px !important;
      height: 16px !important;
      min-height: 16px !important;
      cursor: pointer;
      &:hover {
        color: #27313B;
      }
    }

    // down active - DESC
    &.sorted {
      .sort-icon {
        &.up {
          &:hover {
            color: #27313B;
          }
          color: $border-color-4;
          background-color: transparent;
        }

        &.down {
          &:hover {
            color: #27313B;
          }
          background-color: transparent;
          border-radius: 3px;
          color: #000;
        }
      }
    }

    // up active - ASC
    &.reverse-sorted {
      .sort-icon {
        &.down {
          &:hover {
            color: #27313B;
          }
          color: $border-color-4;
          background-color: transparent;
        }

        &.up {
          &:hover {
            color: #27313B;
          }
          background-color: transparent;
          border-radius: 3px;
          color: #000;
        }
      }
    }
  }

  // table row
  .content {
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: block;
    @include text-style(14px, 400, 16px);
    &.number-format {
      text-align: right;
    }
    &.link {
      color: $am-primary;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &.disabled {
      color: $font-color-disabled !important;
      pointer-events: none;
      &:hover {
        text-decoration: none !important;
        cursor: not-allowed !important;
      }
    }
  }
}
.mat-dark-theme {
  table {
    .sort-area {
      mat-icon.sort-icon:hover {
        color: #27313B !important;
      }
      // down active - DESC
      &.sorted {
        .sort-icon {
          &.up {
            &:hover {
              color: #27313B !important;
            }
            color: $border-color-4 !important;
            background-color: transparent;
          }
  
          &.down {
            &:hover {
              color: #27313B !important;
            }
            background-color: transparent;
            border-radius: 3px;
            color: #fff !important;
          }
        }
      }
  
      // up active - ASC
      &.reverse-sorted {
        .sort-icon {
          &.down {
            &:hover {
              color: #27313B !important;
            }
            color: $border-color-4 !important;
            background-color: transparent;
          }
  
          &.up {
            &:hover {
              color: #27313B !important;
            }
            background-color: transparent;
            border-radius: 3px;
            color: #fff !important;
          }
        }
      }
    }
  }
}
// default selected banner
.selected-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: #111;
  color: #ffffff;
  z-index: 20;
  display: flex;
  align-items: center;
  padding: 16px;
  .number-of-selected {
    white-space: pre;
    margin-right: 16px;
  }
  .selected-actions {
    button {
      opacity: 1 !important;
      background-color: #ffffff;
      color: #27313b;
      &:hover {
        background-color: #f6f8fa !important;
      }

      &:disabled {
        background-color: #f6f8fa !important;
        color: #a8b4bf !important;
      }
    }
  }
}
tr.empty-row-hover {
  &:hover {
    background-color: $bg-color-2 !important;
    td {
      background-color: $bg-color-2 !important;
    }
  }
}